import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

import { firebaseConfig } from '../constants/defaultValues';

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the authentication service
const auth = getAuth(app);

// Get a reference to the database service
const database = getDatabase(app);

export { auth, database };

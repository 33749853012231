import { getLocalURL } from '../helpers/Utils';
export const SERVER_HOST = getLocalURL('MULIN_API_HOST');
export const AUTH_SERVICE_URL = process.env.MULIN_AUTH_SERVICE_URL;
export const SERVER_HOST_SYNC = getLocalURL('MULIN_API_HOST', true);
export const STOREFRONT_BASE_URL = getLocalURL('MULIN_STOREFRONT_BASE_URL');
export const locations = [
  { label: 'GCC', value: 'GULF', key: 0 },
  { label: 'INDIA', value: 'IN', key: 1 }
];
export const excludeAttrSync = ['status'];
export const FLAG =
  localStorage.getItem('LOCATION') === 'IN'
    ? 'https://storage.googleapis.com/dev-bucket.stylifashion.com/flags/flag_in.png'
    : 'https://storage.googleapis.com/dev-bucket.stylifashion.com/GCC_Flag.svg';

export const PODS = localStorage.getItem('LOCATION') === 'IN' ? 'IN' : 'GCC';

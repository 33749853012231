import { UserRole } from '../helpers/authHelper';

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'ar', name: 'Arabic', direction: 'rtl' }
];

const firebaseConfigDev = {
  apiKey: 'AIzaSyBXprkSsdWnA8Ks9HJ7XAjAvt9bcO_iAkQ',
  authDomain: 'stylishopdev.firebaseapp.com',
  projectId: 'stylishopdev',
  storageBucket: 'stylishopdev.appspot.com',
  messagingSenderId: '640935596576',
  appId: '1:640935596576:web:393a09830cb8b64e672245',
  measurementId: 'G-WDEYHW4RET'
};
// const firebaseConfigProd = {
//   apiKey: 'AIzaSyAYZumsppN9Yu-a7vSLlMrP65dlAj2R-Rg',
//   authDomain: 'supplyapps-prod.firebaseapp.com',
//   databaseURL: 'https://supplyapps-prod.firebaseio.com',
//   projectId: 'supplyapps-prod',
//   storageBucket: 'supplyapps-prod.appspot.com',
//   messagingSenderId: '945566594699',
//   appId: '1:945566594699:web:1cff3c482c9b69d684c68e'
// };
const firebaseConfigProd = {
  apiKey: 'AIzaSyBE5jksNFb01168sKeTRjgfInnBQNDtUXU',
  authDomain: 'stylishopprod.firebaseapp.com',
  projectId: 'stylishopprod',
  storageBucket: 'stylishopprod.appspot.com',
  messagingSenderId: '476895285940',
  appId: '1:476895285940:web:3897b49635753e08ba3de1',
  measurementId: 'G-5VP371TRY8'
};

export const firebaseConfig = process.env.NODE_ENV === 'production' ? firebaseConfigProd : firebaseConfigDev;

export const currentUser = {
  id: 1,
  title: '',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin
};

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = process.env.MULIN_API_HOST;

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel'
];
